const initialstate = {
    auth: false,
    UserData: {
        //CompanyId: "Contractor::0a993fc0-532d-11e9-9b97-cf423e284a08",
        //CompanyName: "Test Company",
        //field1: "James King",
        //field2: "ali_rehan@msn.com",
        //key: "Person::01a36390-0355-11ea-b7a9-8b4e1614373f",
        //id: "01a36390",
        //CustomerType: "Contractor",
        //ShortName: "TC",
        //avatar: "http://www.minneapolisheadshots.com/gallery/main/environmentalheadshot.jpg"
    },
    CompanyData: {},
    cid: {
        //CompanyId: "Company::8cad9b70-1c84-11ea-a135-2907f46619f3",
        //CompanyName: "Test Company",
        //field1: "CID Company",
        //field2: "Los Angeles",
        //key: "Company::8cad9b70-1c84-11ea-a135-2907f46619f3",
        //id: "2907f46619f3",
    },
    Pref: [],
    HQData: {},
    ContractorData: {
        //ContractorId: "Data::Cont::Info::Contractor_QNyzgJ2T", //window.ContractorId,
        //CompanyId: "Data::Cont::Info::Contractor_QNyzgJ2T",
        //CustomerType: "Contractor",
        //ContractorName: "Test Contractor",
        //CompanyName: "Test Contractor",
        //field1: "Test Contractor",
        //field2: "",
        //Phone: "800-555-1212",
        //Mobile: "+15623936334",
        //SearchWords: "Mr Contractor",
        //ReplyTo: "NotificationBridge@yahoo.com",
        //pk1: "Data::Cont::Info::Contractor_QNyzgJ2T",
        //key: "Data::Cont::Info::Contractor_QNyzgJ2T",
        //id: "Contractor_Pj2TvjOp"
    },
    DeveloperData: {
        ContractorId: "Developer::Account::0786",
        CompanyId: "Developer::Account::0786",
        CustomerType: "Developer",
        ContractorName: "Developer",
        CompanyName: "Developer",
        field1: "Developer",
        field2: "",
        Phone: "800-555-1212",
        Mobile: "+15623936334",
        SearchWords: "Developer",
        pk1: "Developer::Account::0786",
        key: "Developer::Account::0786",
        id: "8cad9b703"
    },
    NewServicePolicy: {
        key: "",
    },
    FormsPref: {},
    SingleServiceData: {},
    ServiceLandingPage: false,
    NowEditing: "Company",
    CurrentEdit: {},
    TriggerUpdate: false,
    FormsDefault: {},
    FormData: {},
    CurrentCompany: {}
    //Preferences: {}
}



export default function (state = initialstate, { type, payload }) {

    let NewState = state;
    console.log("ReduxState.payload", type, payload)
    switch (type) {
        case 'LOGGED_IN':
            NewState = { ...state, auth: payload }
            //  console.log("ReduxState.LOGGED_IN", type, payload)
            break;
        case 'LOGGED_OUT':
            NewState = { ...state, auth: payload }
            //  console.log("ReduxState.LOGGED_OUT", type, payload)
            break;
        case 'USER_DATA':
            NewState = { ...state, UserData: payload }
            break;
        case 'CONTRACTOR_DATA':
            NewState = { ...state, ContractorData: payload }
            break;
        case 'HQ_DATA':
            NewState = { ...state, HQData: payload }
            break;
        case 'PREF_DATA':
            NewState = { ...state, Pref: payload }
            break;
        case 'SET_CID':
            NewState = { ...state, cid: payload }
            break;
        case 'NOW_EDITING':
            NewState = { ...state, NowEditing: payload }
            break;
        case 'SERVICE_LANDING_PAGE':
            NewState = { ...state, ServiceLandingPage: payload }
            break;
        case 'SINGLE_SERVICE_DATA':
            NewState = { ...state, SingleServiceData: payload }
            break;
        case 'TRIGGER_UPDATE':
            NewState = { ...state, TriggerUpdate: payload }
            break;
        case 'NEW_SVC_POLICY':
            NewState = { ...state, NewServicePolicy: payload }
            break;
        case 'FORMS_PREF':
            NewState = { ...state, FormsPref: payload }
            break;
        case 'FORMS_DEFAULT':
            NewState = { ...state, FormsDefault: payload }
            break;
        case 'FORMS_DATA':
            NewState = { ...state, FormData: payload }
            break; 
        case 'CURRENT_COMPANY':
            NewState = { ...state, CurrentCompany: payload }
            break; 
        case 'CURRENT_EDIT':
            NewState = { ...state, CurrentEdit: payload }
            break; 
        default:
            NewState = state;
    }

    console.log("ReduxState.return", NewState)
    console.log("Testing.Form.Edit.1.Redux.return", NewState)
    

    return NewState;

}
