import React, { useEffect, useState } from 'react';
import {
    Grid,
    FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { useSelector } from 'react-redux';
import { CompanyPrivilageMenu, ContractorPrivilageMenu } from '../utils/Privilage';
//import ContentCopyIcon from '@mui/icons-material/ContentCopy';

//const CompanyPrivilageMenu = [
//    { value: 100, label: "Field Resource" },
//    { value: 200, label: "Assistance Manager" },
//    { value: 300, label: "Manager" },
//];

//const ContractorPrivilageMenu = [
//    { value: 500, label: "Field Resource" },
//    { value: 600, label: "Assistance Manager" },
//    { value: 700, label: "Manager" },
//    { value: 800, label: "Branch Admin" },
//    { value: 1000, label: "Corporate Admin" },
//];


export default function RadioButtonsGroup(props) {
    const UserData = useSelector(state => state.UserData);
    const NowEditing = useSelector(state => state.NowEditing);
    const initialState = {
        PrivilageMenu: [],
        UserDataLevel: 100,
        Level: 100,
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        console.log("PrivilageMenu.props", props)

        let PrivilageMenu = CompanyPrivilageMenu;
        let UserDataLevel = 101;
        let Level = 100;
        if (NowEditing === "Contractor") {
            PrivilageMenu = ContractorPrivilageMenu;
          //  Level = 100;
        }

        if (UserData.Level && UserData.Level > 1) {
            UserDataLevel = UserData.Level;
        }

        if (props.defaultValue) {
            Level = props.defaultValue;
        }

        setState(prevState => ({
            ...prevState,
            PrivilageMenu: PrivilageMenu,
            Level: Level,
            UserDataLevel: UserDataLevel
        }));

    }, [])


    const SetValues = (data, name) => {

        console.log("PrivilageMenu.data,name", data, name)

        let LevelObj = state.PrivilageMenu.find(x => x.value === data);

        setState(prevState => ({
            ...prevState,
            [name]: data
        }));

        if (props.AddressObj){
            props.AddressObj(LevelObj);
        }

    }

    const PrivMenu = (o, indd) => {

        if (o.value <= state.UserDataLevel) {
            return (
                <MenuItem key={indd} value={o.value}>
                    {o.label}
                </MenuItem>
            )
        }
    }


    console.log("PrivilageMenu.state", state)
    console.log("PrivilageMenu.props", props)

    return (state.PrivilageMenu && state.PrivilageMenu.length > 0 && (
                <Grid container spacing={1}>

                    <Grid item xs={12} >

                        <FormControl variant="outlined" style={{ width: '100%' }} >
                            <InputLabel id="demo-simple-select-outlined-label">Authorization Level</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                //id={`select-${Item.key}-${index}`}
                                defaultValue={state.Level}
                                // error={true}
                                required={true}
                                disabled={state.disabled}
                                autoWidth={true}
                                value={state.Level}
                                onChange={(e) => SetValues(e.target.value, "Level")}
                                label="Authorization Level"
                            >
                                {state.PrivilageMenu.map((o, indd) => (PrivMenu(o, indd)))}
                            </Select>
                        </FormControl >

                    </Grid>

                </Grid>
            ) );
}
