
export const CompanyPrivilageMenu = [
    { value: 100, label: "Field Resource" },
    { value: 200, label: "Assistance Manager" },
    { value: 300, label: "Manager" },
];

export const ContractorPrivilageMenu = [
    { value: 500, label: "Field Resource" },
    { value: 600, label: "Assistance Manager" },
    { value: 700, label: "Manager" },
    { value: 800, label: "Branch Admin" },
    { value: 1000, label: "Corporate Admin" },
];