// eslint-disable
// this is an auto generated file. This will be overwritten

//export const onCreateSvcbotDb = `subscription onCreateSvcbotDb{
//    gsi1Pk2
//    gsi1Sk2
//    gsi2Pk2
//    gsi2Sk2
//    pk1
//    sk1
//    dataJSON
//  }
//`;

 export const onCreateSvcbotDb = `subscription OnCreateSvcbotDb(
   $gsi1Pk2: String
   $gsi2Pk2: String
   $pk1: String
 ) {
   onCreateSvcbotDb(
     gsi1Pk2: $gsi1Pk2
     gsi2Pk2: $gsi2Pk2
     pk1: $pk1
   ) {
     dataJSON
   }
 }
 `;

export const onCreateSvcbotGSI2PK2 = `subscription OnCreateSvcbotGSI2PK2(
  $gsi1Pk2: String
  $gsi2Pk2: String
  $pk1: String
) {
  onCreateSvcbotGSI2PK2(
input:[{
    gsi1Pk2: $gsi1Pk2
    gsi1Sk2: $gsi1Sk2
    gsi2Pk2: $gsi2Pk2
    gsi2Sk2: $gsi2Sk2
    pk1: $pk1
  }]) {
    gsi1Pk2
    gsi1Sk2
    gsi2Pk2
    gsi2Sk2
    pk1
    sk1
    dataJSON
  }
}
`;

// export const onCreateSvcbotGSI2PK2 = `subscription OnCreateSvcbotGSI2PK2{
//   onCreateSvcbotGSI2PK2{
//     gsi1Pk2
//     gsi1Sk2
//     gsi2Pk2
//     gsi2Sk2
//     pk1
//     sk1
//     dataJSON
//   }
// }
// `;


export const onCreateSvcbotDbpk = `subscription OnCreateSvcbotDb(
    $pk1: String
) {
  onCreateSvcbotDb(
    pk1: $pk1
  ) {
    dataJSON
  }
}
`;


export const onCreateSvcbotDbgsi1 = `subscription OnCreateSvcbotDb(
    $gsi1Pk2: String
) {
  onCreateSvcbotDb(
    gsi1Pk2: $gsi1Pk2
  ) {
    dataJSON
  }
}
`;
//
export const onUpdate = `subscription OnUpdate(
    $pk1: String
) {
  onUpdate(
    pk1: $pk1
  ) {
    dataJSON
  }
}
`;

export const onCreatePk = `subscription OnCreatePk(
    $pk1: String
) {
  onCreatePk(
    pk1: $pk1
  ) {
    dataJSON
  }
}
`;

export const onCreateSvcbotDbgsi2 = `subscription OnCreateSvcbotDb(
    $gsi2Pk2: String
) {
  onCreateSvcbotDb(
    gsi2Pk2: $gsi2Pk2
  ) {
    dataJSON
  }
}
`;


//type SvcbotDb @aws_api_key
//@aws_iam {
//    gsi1Pk2: String
//    gsi1Sk2: String
//    gsi2Pk2: String
//    gsi2Sk2: String
//    pk1: String
//    sk1: String
//    dataJSON: AWSJSON
//}


//export const onBatchInputs = `subscription OnBatchInputs(
//  $pk1: String
//) {
//  onBatchInputs(
//     pk1: $pk1 
//  ) {
//    gsi1Pk2
//    gsi1Sk2
//    gsi2Pk2
//    gsi2Sk2
//    pk1
//    sk1
//    dataJSON
//  }
//}
//`;

//onBatchInputs(pk1: String): SvcbotDb
//@aws_subscribe(mutations: ["createBatch"])

//$gsi1Pk2: String
//$gsi1Sk2: String
//$gsi2Pk2: String
//$gsi2Sk2: String
//$pk1: String
//  ) {
//    onBatchInputs(
//        gsi1Pk2: $gsi1Pk2
//      gsi1Sk2: $gsi1Sk2
//      gsi2Pk2: $gsi2Pk2
//      gsi2Sk2: $gsi2Sk2
//      pk1: $pk1
//    ) {
//        dataJSON
//        gsi1Pk2
//        gsi1Sk2
//        gsi2Pk2
//        gsi2Sk2
//        pk1
//        sk1
//    }
//}


export const onBatchInputs = `subscription OnBatchInputs($pk1: String){
    onBatchInputs(pk1: $pk1) {
        dataJSON
        gsi1Pk2
        gsi1Sk2
        gsi2Pk2
        gsi2Sk2
        pk1
        sk1
    }
}
`;

//export const createBatch = `mutation CreateBatch($input: [CreateSvcbotDbInput!]) {
//  createBatch(input: $input) {
//    pk1
//    sk1
//  }
//}
//`;

export const onDeleteSvcbotDb = `subscription OnDeleteSvcbotDb(
  $gsi1Pk2: String
  $gsi1Sk2: String
  $gsi2Pk2: String
  $gsi2Sk2: String
  $pk1: String
) {
  onDeleteSvcbotDb(
    gsi1Pk2: $gsi1Pk2
    gsi1Sk2: $gsi1Sk2
    gsi2Pk2: $gsi2Pk2
    gsi2Sk2: $gsi2Sk2
    pk1: $pk1
  ) {
    gsi1Pk2
    gsi1Sk2
    gsi2Pk2
    gsi2Sk2
    pk1
    sk1
  }
}
`;
export const onUpdateSvcbotDb = `subscription OnUpdateSvcbotDb(
  $gsi1Pk2: String
  $gsi1Sk2: String
  $gsi2Pk2: String
  $gsi2Sk2: String
  $pk1: String
) {
  onUpdateSvcbotDb(
    gsi1Pk2: $gsi1Pk2
    gsi1Sk2: $gsi1Sk2
    gsi2Pk2: $gsi2Pk2
    gsi2Sk2: $gsi2Sk2
    pk1: $pk1
  ) {
    gsi1Pk2
    gsi1Sk2
    gsi2Pk2
    gsi2Sk2
    pk1
    sk1
  }
}
`;
