import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
//import clsx from 'clsx';
import {
    Box,
    Button,
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Breadcrumbs,
    Link
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Search as SearchIcon } from 'react-feather';
import AutoComplateBox from '../components/AutoComplete';
import { NewPageId } from '../utils/CommonGraphql';
import Tabs from '../components/Tabs';
import { useDispatch } from 'react-redux';
import { ContractorTabs, CompanyTabs, PersonTabs, AssetTabs, HQTabs } from './TabsList';
import Page from '../components/Page';
import ToolbarHeading from '../components/ToolbarHeading';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

//const useStyles = makeStyles((theme) => ({
//    root: {
//        margin: theme.spacing(1),
//        backgroundColor: theme.palette.background.dark,
//        minHeight: '100%',
//        paddingBottom: theme.spacing(3),
//        paddingTop: theme.spacing(3)
//    },
//    importButton: {
//        marginRight: theme.spacing(1)
//    },
//    exportButton: {
//        marginRight: theme.spacing(1)
//    }
//}));

const Newpageiddd = NewPageId("toolbar")

const Toolbar = (props) => {
    //  const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const [SelectCompany, SetSelectCompany] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const items = useLocation();
    let navigate = useNavigate();

    const initialState = {
        CurrentCompany: UserData.CompanyName,
        CompanyId: UserData.CompanyId,
        CompanyName: UserData.CompanyName,
        CustomerType: UserData.CustomerType,
        TabList: []
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {

        NewValues()

    }, [])

    useEffect(() => {

        NewValues()

    }, [items, params])

    const NewValues = () => {

        console.log('CompanyPage.params', params);
        console.log('CompanyPage.items', items);

        let TabList = [];
        let CompanyId = UserData.CompanyId;
        let CustomerType = UserData.CustomerType;
        let CompanyName = UserData.CompanyName;
        let NowEditing = UserData.CustomerType;
        let BranchType = UserData.BranchType;
        let UserEditor = UserData.CustomerType;
        let BranchId = UserData.BranchId;
        let HQId = UserData.HQId;
        //let field1 = UserData.field1;
        let ActiveKey = UserData.key;
        let sk1 = UserData.sk1;
        let field1 = UserData.CompanyName;
        
        let Category = "Company";
        let ContractorId = ContractorData.CompanyId;
        let ContractorName = ContractorData.CompanyName;
        let Refresh = NewPageId("CP");

        let BreadCrum1 = ContractorData.field1;
        let Linkdata1 = ContractorData;
        let BreadCrum2 = "";
        let Linkdata2 = "";
        
        console.log('CompanyPage.NowEditing-109', NowEditing);
        console.log('CompanyPage.CompanyId.Userdata', Category, CompanyId);

        //if (BranchType === "HQ") {
        //    Category = "HQ";
        //}

        //  using this module to show company data
        if (params.CompanyId) {
            CompanyId = params.CompanyId;
            CompanyName = params.CompanyName;
            CustomerType = "Company";
            field1 = CompanyName;
            //  BreadCrum1 = "";
            console.log('CompanyPage.CompanyId.params', CustomerType, Category, CompanyId);
        }

        //if contractor is going to its page, they use contractor as CustomerType
        if (UserData.CompanyId === params.CompanyId) {
            CompanyId = UserData.CompanyId;
            CustomerType = UserData.CustomerType;
            NowEditing = UserData.CustomerType;
            CompanyName = UserData.CompanyName;
            console.log('CompanyPage.NowEditing-132', NowEditing);
            console.log('CompanyPage.CompanyId.params=UserData', CustomerType, Category, CompanyId);
        }

        if (items && items.state) {
            field1 = items.state.field1;
            //   BreadCrum1 = (<Link to={`../${items.state.CompanyId}/${items.state.CompanyName}`}>{items.state.CompanyName}</Link>);

            NowEditing = items.state.CustomerType;
            sk1 = items.state.sk1;
            Category = items.state.Category;
            CompanyId = items.state.CompanyId;
            CompanyName = items.state.CompanyName;
            BranchId = items.state.BranchId;
            HQId = items.state.HQId;
            ContractorId = items.state.ContractorId;
            ContractorName = items.state.ContractorName;
            ActiveKey = items.state.sk1;
           // ActiveKey = items.state.key;

            if (Category === "Person") {
                field1 = items.state.field1;
            }

            if (Category === "Company") {
                BreadCrum2 = items.state.field1;
                Linkdata2 = items.state;
            }

            if (Category === "Branch") {
                CompanyId = items.state.key;
                CompanyName = items.state.field1;
                BranchType = items.state.BranchType;
                //   UserEditor = items.state.CustomerType;
            }

            // if (Category === "Branch" && BranchType === "HQ") {
            //     Category = "HQ";
            //  }

            //   CustomerType = items.state.CustomerType;
            console.log('CompanyPage.NowEditing-172', NowEditing);
        }

        let TapsProps = {
            CompanyId: CompanyId,
            CustomerType: CustomerType,
            CompanyName: CompanyName,
            NowEditing: NowEditing,
            Activefield1: field1,
            ActiveCategory: Category,
            ActiveKey: ActiveKey,
            UserEditor: UserEditor,
            BranchId: BranchId,
            HQId: HQId,
            Category: Category,
            ContractorId: ContractorData.CompanyId,
            ContractorName: ContractorData.CompanyName,
            ItemData: items.state,
            ContractorType: "Contractor"
        }

        console.log('CompanyPage.TapsProps', TapsProps);

        dispatch({ type: 'NOW_EDITING', payload: NowEditing });

        switch (Category) {
            case "Company":
                dispatch({ type: 'CURRENT_COMPANY', payload: items.state });
                if (CustomerType === "Contractor") {
                    TabList = ContractorTabs(TapsProps);
                    BreadCrum1 = "";
                    Linkdata1 = "";
                    BreadCrum2 = "";
                    Linkdata2 = "";
                } else {
                    TabList = CompanyTabs(TapsProps);
                    BreadCrum1 = ContractorData.field1;
                    Linkdata1 = ContractorData;
                    BreadCrum2 = "";
                    Linkdata2 = "";
                }
                break;
            case "Person":
                TabList = PersonTabs(TapsProps);
                if (NowEditing === "Contractor") {
                    BreadCrum1 = ContractorData.field1;
                    Linkdata1 = ContractorData;
                    BreadCrum2 = "";
                    Linkdata2 = "";
                } else {
                    BreadCrum1 = ContractorData.field1;
                    Linkdata1 = ContractorData;
                    BreadCrum2 = CurrentCompany.CompanyName;
                    Linkdata2 = CurrentCompany;
                }
                
                break;
            case "Location":
                TabList = AssetTabs(TapsProps);

                if (NowEditing === "Contractor") {
                    BreadCrum1 = ContractorData.field1;
                    Linkdata1 = ContractorData;
                    BreadCrum2 = "";
                    Linkdata2 = "";
                } else {
                    BreadCrum1 = ContractorData.field1;
                    Linkdata1 = ContractorData;
                    BreadCrum2 = CurrentCompany.CompanyName;
                    Linkdata2 = CurrentCompany;
                }

                break;
            case "HQ":
                TabList = HQTabs(TapsProps);
                break;
            case "Contractor":
                TabList = ContractorTabs(TapsProps);
                BreadCrum1 = "";
                BreadCrum2 = "";
                dispatch({ type: 'CONTRACTOR_DATA', payload: items.state });
                break;
            case "Branch":
                TabList = ContractorTabs(TapsProps);
                BreadCrum1 = "";
                BreadCrum2 = "";
                dispatch({ type: 'CONTRACTOR_DATA', payload: items.state });
                break;
            case "Equipment":
                TabList = AssetTabs(TapsProps);
                if (NowEditing === "Contractor") {
                    BreadCrum1 = ContractorData.field1;
                    Linkdata1 = ContractorData;
                    BreadCrum2 = "";
                    Linkdata2 = "";
                } else {
                    BreadCrum1 = ContractorData.field1;
                    Linkdata1 = ContractorData;
                    BreadCrum2 = CurrentCompany.CompanyName;
                    Linkdata2 = CurrentCompany;
                }
                break;
            default:
                TabList = CompanyTabs(TapsProps);
        }

        console.log('CompanyPage.CompanyId.end', Category, CustomerType, CompanyId);
        console.log('CompanyPage.TabList.items.state', items.state);
        console.log('CompanyPage.TabList.TapsProps', TapsProps);
        console.log('CompanyPage.TabList.tabs', TabList);

        if (UserData.CustomerType !== "Contractor") {
            BreadCrum1 = "";
            BreadCrum2 = "";
        }

        setState(prevState => ({
            ...prevState,
            TabList: TabList,
            Category: Category,
            CompanyId: CompanyId,
            CustomerType: CustomerType,
            CompanyName: CompanyName,
            field1: field1,
            BreadCrum1: BreadCrum1,
            BreadCrum2: BreadCrum2,
            sk1: sk1,
            Refresh: Refresh,
            Linkdata1: Linkdata1,
            Linkdata2: Linkdata2
        }));

    }

    const DetailLink = (data) => {
        let Path = `/app/company/${data.CompanyId}`;
        navigate(Path, { state: data });
    }


    return (
        <Page
            //  className={classes.root}
            title="Account"
        >
            <Grid container spacing={1} >

                <Grid item xs={12}>
                    <Typography variant="h2">
                        {state.field1}
                    </Typography>
                </Grid>

                {state.BreadCrum1 !== "" && (
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            {state.BreadCrum1 && (
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    onClick={() => DetailLink(state.Linkdata1)}
                                >
                                    {state.BreadCrum1}
                                </Link>
                            )}
                            {state.BreadCrum2 && (
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    onClick={() => DetailLink(state.Linkdata2)}
                                >
                                    {state.BreadCrum2}
                                </Link>
                            )}
                        </Breadcrumbs>
                    </Grid>
                )}

                <Grid item xs={12} >

                    <Box mt={3}>
                        {state.TabList && (
                            <Tabs
                                TabList={state.TabList}
                                key={state.Refresh + "key"}
                                id={state.Refresh + "id"}
                            />
                        )}
                    </Box>

                </Grid>
            </Grid>
        </Page>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string,
    ProcessSearch: PropTypes.func
};

export default Toolbar;
