import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import { useSelector } from 'react-redux';
//import moment from 'moment';
import {
    FormGroup,
    FormControlLabel,
    Switch,
    Button,
    TextField,
    Typography,
    Grid,
    CardHeader,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SelectAssets from '../components/SelectAssets';
//import Quill from '../components/quill'
import {
    NewPageId,
    removeEmptyStringElements,
    UpdateData
} from '../utils/CommonGraphql';
import { createSvcbotDb } from '../graphql/mutations';
import AutoComplateBox from '../components/AutoComplete';
import { API, graphqlOperation } from "aws-amplify";
//import { useDispatch } from 'react-redux';
import Page from '../components/Page';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import CloudUpload from '@mui/icons-material/CloudUpload';
//import { currentTime } from '../utils/DateTime';
import UploadMain from '../Upload/UploadMain';

const NewId = NewPageId("AM")

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        //minHeight: '100%',
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));

var dateToday = new Date();
let currentTime = dateToday.toISOString().replace("Z", "");


const Profile = ({ className, ...rest }) => {
    const classes = useStyles();
    //const [Requestor, SetRequestor] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const [SelectCompany, SetSelectCompany] = useState(false);
    const [AddPersonal, SetAddPersonal] = useState(false);
    const [Upload, SetUpload] = useState(false);
    //const cid = useSelector(state => state.cid);
    //const dispatch = useDispatch()

    const initialState = {
        Message: "",
        MessageNo: "",
        Personal: [],
        PrivateMessage: false,
        CurrentCompany: UserData.CompanyName,
        CompanyId: UserData.CompanyId,
        NewId: NewId,
        PhotoService: [],
        Documents: [],
        ImageStateSet: [],
    }

    const [state, setState] = useState(initialState);



    //useEffect(() => {

    //  if (rest.MessageNo) {
    //    setState(prevState => ({
    //      ...prevState,
    //      MessageNo: `# ${rest.MessageNo}`
    //    }));
    //  }

    //}, [rest.MessageNo])

    const SendValuesBack = () => {

        let TotalMessage = {
            Message: state.Message,
            Person: state.Personal,
            PrivateMessage: rest.Trigger1
        }

        if (rest && rest.SelectedValues) {
            rest.SelectedValues(TotalMessage)
        }

    }

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    //const PrivateMessageProcess = (e, name) => {

    //  if (name === "Personal" && e && e.length > 0 ) {

    //  }

    //  setState(prevState => ({
    //    ...prevState,
    //    [name]: e
    //  }));


    //}

    const GetCompanyName = (data) => {

        setState(prevState => ({
            ...prevState,
            CurrentCompany: data.field1,
            CompanyId: data.CompanyId,
        }));

        SetSelectCompany(false);

    }

    const SaveImage = (name, e) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    };

    const ProcessMessage = () => {
        
        let NotificationList = [];
        let PrivateMessageList = [UserData.key];
        console.log("AddMessage.state.Personal", state.Personal)
        //create a list for email and Private message
        if (state.Personal.length > 0) {
            state.Personal.map(each => {
                PrivateMessageList.push(each.key)
                NotificationList.push({
                    field1: each.field1,
                    email: each.email,
                    mobile: each.mobile,
                    phone: each.phone,
                    pk1: each.CompanyId,
                    sk1: each.key
                })
            })
        }

        console.log("AddMessage.NotificationList", NotificationList)
        //put keys only if Private message is true
        if (!state.PrivateMessage) {
            PrivateMessageList = [];
        }

        let SREvent = rest.SREvent;
        let EventData = SREvent.EventData;
        let NewEventData = [];
        let TotalLength = EventData.length;
        let NewEventAdd = {
            PhotoService: state.PhotoService,
            Documents: state.Documents,
            key: state.NewId,
            Body: `${rest.DrawerTitle}: ${state.Message}`,
            TimeStamp: currentTime,
            SenderName: UserData.field1,
            PosterId: UserData.key,
            Avatar: UserData.Avatar,
            SenderCompany: UserData.CompanyName,
            SenderCompanyType: UserData.CustomerType,
            SenderCompanyId: UserData.CompanyId,
            PrivateMessage: state.PrivateMessage,
            PrivateMessageList: PrivateMessageList,
            MessageRead: [UserData.key],
        }

        if (rest.UpdateNumber < TotalLength) {
            EventData.splice(rest.UpdateNumber + 1, 0, NewEventAdd);
            NewEventData = EventData;
        } else {
            NewEventData = [...EventData, NewEventAdd];
        }

        SREvent.status = rest.Category;

        if (state.PhotoService.length > 0) {
            let PhotoAdd = [...SREvent.PhotoService, ...state.PhotoService];
            SREvent.PhotoService = PhotoAdd;
        }

        if (state.Documents.length > 0) {
            let DocumentsAdd = [...SREvent.Documents, ...state.Documents];
            SREvent.Documents = DocumentsAdd;
        }

        SREvent.EventData = NewEventData;

        if (rest.UpdateEvent) {
            rest.UpdateEvent(SREvent);
        }

        UpdateDatabase(NotificationList, SREvent, NewEventAdd);

        console.log("AddMessage.NewEventData", NewEventData)
    }

    const UpdateDatabase = (NotificationList, SREvent, NewEventAdd) => {

        let dataJSON = {
            NotificationList: NotificationList,
            Action: rest.Category
        }

        //let DBInput = {
        //    pk1: `Post::${SREvent.PostId}`,
        //    sk1: `Post::Response::${rest.Category}::${currentTime}`,
        //    gsi1Pk2: SREvent.PersonData.CompanyId,
        //    gsi1Sk2: `Post::Response::${rest.Category}::${currentTime}`,
        //    gsi2Pk2: SREvent.ContractorData.gsi2Pk2,
        //    gsi2Sk2: `Post::Response::${rest.Category}::${currentTime}`,
        //    dataJSON: JSON.stringify(removeEmptyStringElements(dataJSON))
        //}

        const ResponseTimeTrack = {
            pk1: `Post::Response::${SREvent.posterId.CompanyId}`,
            sk1: SREvent.ServiceRequest.sk1,
            gsi1Pk2: `Post::${SREvent.PostId}`,
            gsi1Sk2: `Post::Response::${rest.Category}::${currentTime}`,
            gsi2Pk2: `Post::Response::${SREvent.ContractorData.ContractorId}`,
            gsi2Sk2: currentTime,
          //  dataJSON: JSON.stringify(removeEmptyStringElements(dataJSON))
        };

       // API.graphql(graphqlOperation(createSvcbotDb, { input: DBInput }));

        UpdateData(ResponseTimeTrack)
            .then((up) => {
                // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                console.log("ProgressStepper.UpdateDatabase", up)
            })
            .catch((err) => console.log("UpdateDatabase.failed", err));

        if (NotificationList.length > 0) {

            GoToLambda(NotificationList, SREvent, NewEventAdd);

        } else {

            if (rest.CloseDrawer) {
                rest.CloseDrawer();
            }
        }
    }

    const GoToLambda = (NotificationList, event, NewEventAdd) => {

        try {

            var emailstring = {

                title: event.title,
                Avatar: NewEventAdd.Avatar,
                BackgroundColor: "#eeeeee",
                ButtonColor: "#44872e",
                HeadingColor: "#44872e",
                ButtonTextColor: "#ffffff",
                ContractorName: NewEventAdd.SenderCompany,
                ActionButton: `https://bomrng.com/${event.PostId}`,
                ContractorLogo: event.ContractorData.dataJSON.Avatar,
                ActionButtonTitle: "See Details Online",
                headerBanner: "https://images.pexels.com/photos/4050299/pexels-photo-4050299.jpeg?cs=srgb&dl=woman-sitting-on-the-floor-using-a-laptop-4050299.jpg&fm=jpg",
                PhotoService: NewEventAdd.PhotoService,
                Documents: NewEventAdd.Documents,
                SpecialInstructions: "",
                PreSelectList: [],
                UnsubscribeLink: "https://bomrng.com/UnsubscribeLink",
                // email: "ali.s.rehan@jci.com", //each person email
                // title: event.title,
                //  Message: NewEventAdd.Body,
                postHTML: NewEventAdd.Body,
                postTEXT: NewEventAdd.Body,
                PersonList: NotificationList,
                PostId: event.PostId,
                ContractorId: event.ContractorData.gsi2Pk2,
                CompanyId: event.PersonData.CompanyId,
                ReplyEmail: event.SMSFeedbackMessages.ReplyEmail

            };

            console.log("AddMessage.emailstring", emailstring)

            if (emailstring) {
                API.post("TriggerEmail", "/post", {
                    body: { Records: emailstring }
                }).then(() => {
                    if (rest.CloseDrawer) {
                        rest.CloseDrawer();
                    }
                })
            }

        } catch (err) { }

    }

    //console.log("Requestor.ContractorData", ContractorData)
    //console.log("Requestor.UserData", UserData)
    //console.log("Requestor.cid", cid)
    //console.log("Requestor.state", state)
    console.log("AddMessage.props", rest)
    console.log("AddMessage.state", state)

    return (
        <Page className={classes.paper}>
            <Grid container spacing={1}>

                <Grid xs={12} item>

                    <Typography variant="h4">
                        {rest.DrawerTitle}
                    </Typography>
                </Grid>

                <Grid xs={12} item>

                    <TextField
                        label="Add Message"
                        name="Message"
                        value={state.Message || ""}
                        fullWidth
                        multiline
                        rows={2}
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "Message")}
                    />
                </Grid>

                <Grid xs={12} item>
                    <FormGroup>
                        <FormControlLabel control={<Switch
                            //disabled={state.Priority === "Emergency"}
                            checked={state.PrivateMessage || ""}
                            onChange={(e) => SetValues(e.target.checked, "PrivateMessage")}
                        />} label="Private Message" />
                    </FormGroup>
                </Grid>

                {state.PrivateMessage && (
                    <Grid item xs={12} >
                        <Typography>
                            The Message will show for only the personal selected below
                        </Typography>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Button
                        onClick={() => SetAddPersonal(!AddPersonal)}
                        //variant="contained"
                        //color="primary"
                        color="secondary"
                        startIcon={<AddCircleOutline />}
                    >
                        Add Personal/Email Notification
                    </Button>
                </Grid>

                {AddPersonal && (
                    <>
                        <Grid item xs={12} sm={6} >

                            <Typography variant="h4">
                                {state.CurrentCompany}
                            </Typography>

                            {UserData.CustomerType === "Contractor" && rest.Category !== "Company" && (
                                <Button
                                    //disabled={activeStep === 0}
                                    variant="contained"
                                    onClick={() => SetSelectCompany(!SelectCompany)}
                                    className={classes.button}
                                >
                                    Change Company
                                </Button>
                            )}

                        </Grid>

                        {SelectCompany && UserData.CustomerType === "Contractor" && (
                            <Grid
                                item
                                xs={12}
                            >
                                <AutoComplateBox
                                    //SaveDetails={rest.SaveDetails}
                                    //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                    Category="Company"
                                    FormType="Company"
                                    title="Company"
                                    // id={Newpageiddd}
                                    ShowEditButton={false}
                                    CompanyId={ContractorData.CompanyId}
                                    ReturnBackData={e => console.log(e)}
                                    PreviewEdit={false}
                                    EditItem={e => console.log("EditItem", e)}
                                    //FormData={each.FormData}
                                    onSubmit={(model) => GetCompanyName(model)}
                                />
                            </Grid>
                        )}

                        <Grid xs={12} item>
                            <SelectAssets
                                SelectedAsset={(e) => SetValues(e, "Personal")}
                                CompanyId={state.CompanyId}
                                CompanyName={state.CurrentCompany}
                                Personal={rest.LastStep || []}
                                title="Select Personal"
                                Category="Person"
                            // FormData={rest.FormData}
                            />
                        </Grid>

                    </>
                )}

                <Grid item xs={12}>
                    <Button
                        onClick={() => SetUpload(!Upload)}
                        //variant="contained"
                        //color="primary"
                        color="secondary"
                        startIcon={<CloudUpload />}
                    >
                        Upload Document / Pictures
                    </Button>
                </Grid>

                {Upload && (

                    <UploadMain
                        id="PhotoService"
                        key="PhotoService"
                        title="Upload Images, PDF, or Zip"
                        ImageList={(e) => SaveImage("PhotoService", e)}
                        DocumentList={(e) => SaveImage("Documents", e)}
                        coid={ContractorData.ContractorId}
                        ImageStateSet={(e) => SaveImage("ImageStateSet", e)}
                        ImageStateSetBack={state.ImageStateSet}
                        fileallowed="any"
                        cat="PhotoNamePlate"
                    />

                )}

                <Grid xs={12} item>
                    <Button color="primary" variant="contained" onClick={() => ProcessMessage()}>
                        {rest.DrawerTitle}
                    </Button>
                </Grid>

            </Grid>
        </Page>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
